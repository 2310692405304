"use strict"

//////////////////////////////////////////////
//////////////////////////////////////////////
// Booleans
//////////////////////////////////////////////
//////////////////////////////////////////////

/**
isEmpty is a helper function to determine if thing is empty. 

Functions are considered always not empty. 

Arrays: Only if an array has no elements it is empty.  isEmpty does not check
element contents.  (For item contents, do: `isEmpty(array[0])`)

Objects are empty if they have no keys. (Returns len === 0 of object keys.)

NaN returns true.  (NaN === NaN is always false, as NaN is never equal to
anything. NaN is the only JavaScript value unequal to itself.)

Don't use on HTMl elements. For HTML elements, use the !== equality check
(element !== null). TODO fix this

Cannot use CryptoKey with this function since (len === 0) always. 

@param   {any}     thing    Thing you wish was empty.  
@returns {boolean}          Boolean.  
*/
function isEmpty(thing) {
	if (typeof thing === 'function') {
		return false
	}

	if (Array.isArray(thing)) {
		if (thing.length == 0) {
			return true
		}
	}

	if (thing === Object(thing)) {
		if (Object.keys(thing).length === 0) {
			return true
		}
		return false
	}

	if (!isBool(thing)) {
		return true
	}
	return false
}


/**
Helper function to determine boolean.  

Javascript, instead of considering everything false except a few key words,
decided everything is true instead of a few key words.  Why?  Because
Javascript.  This function inverts that assumption, so that everything can be
considered false unless true. 
@param   {any}      bool   Thing that you wish was a boolean.  
@returns {boolean}         An actual boolean.
*/
function isBool(bool) {
	if (
		bool === false ||
		bool === "false" ||
		bool === undefined ||
		bool === "undefined" ||
		bool === "" ||
		bool === 0 ||
		bool === "0" ||
		bool === null ||
		bool === "null" ||
		bool === "NaN" ||
		Number.isNaN(bool) ||
		bool === Object(bool) // isObject
	) {
		return false
	}
	return true
}

/**
Helper function "null" types.  Useful on things where there's a difference
between the zero and "null" value. 

For example, in Hex conversion "" is not equal to 0.  Hex "" is "" while 0 is
Hex 00.  
@param   {any}     thing    Thing that you wish was null.  
@returns {boolean}          An actual boolean.
*/
function isNull(thing) {
	if (
		thing === "" ||
		thing === null ||
		thing.toLowerCase() === "null" ||
		thing === undefined ||
		thing.toLowerCase() === "undefined"
	) {
		return true
	}
	return false
}


/**
Helper function to determine true over bool, string, and int types.
@param   {any}    thing     Thing that you wish was a true.  
@returns {boolean}          An actual true.  
*/
function isTrue(thing) {
	if (thing === true ||
		(typeof thing == "string" && thing.toLowerCase() === "true") ||
		(parseInt(thing) === 1)) {
		return true
	}
	return false
}


//////////////////////////////////////////////
//////////////////////////////////////////////
// Time
//////////////////////////////////////////////
//////////////////////////////////////////////

// Now returns Unix time.  
function Now() {
	return (Math.floor(Date.now() / 1000))
}


//////////////////////////////////////////////
//////////////////////////////////////////////
// Visibility
//////////////////////////////////////////////
//////////////////////////////////////////////

/**
Clear removes innerHTML element.  
@param {string|element} element     string id of element or element.
*/
function Clear(element) {
	if (typeof element == "string") {
		element = document.getElementById(element)
	}
	if (element == null) {
		console.warn("element does not exist")
		return
	}
	element.innerHTML = ""
}


/**
SetHTML sets innerHTML of element.  
@param {string|element} element     string id of element or element.
@param {html}           html        string id of element or element.
*/
function SetHTML(element, html) {
	if (typeof element == "string") {
		element = document.getElementById(element)
	}
	if (element == null) {
		console.warn("element does not exist")
		return
	}
	element.innerHTML = html
}


/**
Show makes an element Visible.
@param {string|element} element     string id of element or element.
*/
function Show(element) {
	if (typeof element == "string") {
		element = document.getElementById(element)
	}
	if (element == null) {
		console.warn("element does not exist")
		return
	}
	element.hidden = false
}


/**
Hide hides an element.
@param {string|element} element
*/
function Hide(element) {
	if (typeof element == "string") {
		var element = document.getElementById(element)
	}
	if (element == null) {
		console.warn("element does not exist")
		return
	}
	element.hidden = true
}


/**
ToggleVisible toggles an element's visibility
@param   {string|element}  id    String id of element or element.
@returns {boolean}               If the element was hidden.
*/
function ToggleVisible(element) {
	if (typeof element == "string") {
		element = document.getElementById(element)
	}
	if (element == null) {
		console.warn("element does not exist")
		return false
	}

	// console.log("Hidden: " + element.hidden)
	if (isBool(element.hidden)) {
		element.hidden = false
		return false
	}
	element.hidden = true
	return true
}


/**
isVisible returns whether or not the given element is visible. Passing in
an empty element fails and Javascript logs an error trying to access
a null reference.
@param   {string|element} element 
@returns {boolean}    If the element was hidden
*/
function isVisible(element) {
	if (typeof element == "string") {
		var element = document.getElementById(element)
	}
	if (element == null) {
		console.warn("element does not exist")
		return false
	}
	return !element.hidden // hidden is a html boolean.
}

/**
Disable marks an element as disabled. 
@param {string|element} id     string id of element or element.
@returns {void}
*/
function Disable(element) {
	if (typeof element == "string") {
		var element = document.getElementById(element)
	}
	if (element == null) {
		console.warn("element does not exist")
		return
	}
	element.disabled = true
}

/**
Enable marks an element as not disabled.
@param {string|element} id     string id of element or element.
*/
function Enable(element) {
	if (typeof element == "string") {
		var element = document.getElementById(element)
	}
	if (element == null) {
		console.warn("element does not exist")
		return
	}
	element.disabled = false
}

//////////////////////////////////////////////
//////////////////////////////////////////////
// Clipboard
//////////////////////////////////////////////
//////////////////////////////////////////////

/**
CopyToClipBoardElement copys the contents of an element to the clipboard.
@param   {string|element} element         An element from the page.
@returns {void}
*/
function ClipboardE(element) {
	if (typeof element == "string") {
		element = document.getElementById(element)
	}
	var selection = window.getSelection()
	var range = document.createRange()
	range.selectNodeContents(element)
	selection.removeAllRanges()
	selection.addRange(range)
	document.execCommand('copy') //add to clipboard.
}

/**
CopyStringToClipboard accepts a string, creates a hidden element on the page
populated with string, and then copies string to the clipboard. Input string
may be ID of element or string to copy.
@param   {string}  string  Literal string to be copied to the clipboard.
@returns {void}
*/
function ClipboardS(string) {
	// Can only copy elements from DOM.  Create DOM element and copy.  
	let copyDiv = document.getElementById('copy_to_clipboard')
	if (isEmpty(copyDiv)) {
		let hiddenElement = document.createElement('div')
		hiddenElement.setAttribute('class', "hidden")
		hiddenElement.setAttribute('id', "copy_to_clipboard")

		document.body.append(hiddenElement)
		copyDiv = hiddenElement
	}
	copyDiv.textContent = string

	// Set hidden after copy since cannot copy from hidden.
	copyDiv.hidden = false
	ClipboardE(copyDiv)
	copyDiv.hidden = true
}